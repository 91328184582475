<template>
    <a-layout-content class="ProductTag">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <a-layout style="padding: 24px 0; background: #fff">
                <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                    <div class="content-header">
                        <h2>{{ this.$route.name }}</h2>
                        <div class="content-header__left">
                            <a-button type="primary" icon="plus-circle">Thêm thẻ mới</a-button>

                            <a-input-search
                                    placeholder="Tìm kiếm..."
                                    style="width: 200px; margin-left: 30px"
                                    @change="(e) => this.search = e.target.value"
                            />
                        </div>
                    </div>
                    <a-divider />

                </a-layout-content>
            </a-layout>

        </div>
    </a-layout-content>
</template>

<script>
    import './_Product.scss';

    export default {
        name: "ProductTag"
    }
</script>

<style scoped>

</style>